<script setup>
import { Link } from '@inertiajs/vue3';

const props = defineProps([
  'errors', 'terms', 'info', 'ASIC_disclaimer', 'doBN'
])
const emit = defineEmits([
  'update:terms',
  'update:info',
  'update:update:ASIC_disclaimer'
])
</script>

<template>
  <fieldset>
    <legend class="sr-only">Terms & Conditions of Service</legend>
    <div class="space-y-5">
      <div class="relative flex items-start">
        <div class="flex items-center h-6">
          <input
              @change="$emit('update:terms', $event.target.checked)"
              v-model="props.terms" required id="terms" aria-describedby="terms-description" name="terms" type="checkbox" class="w-4 h-4 text-stone-600 border-gray-300 rounded focus:ring-stone-600">
        </div>
        <div class="ml-3 text-sm leading-6">
          <label for="terms" class="font-medium text-gray-900">I have
            read and accept the <Link class="text-stone-700" target='_blank' href="/terms_and_conditions">Terms & Service of use.</Link></label>
          <p id="terms-description" class="text-gray-600">I authorise an
          Accredited Tax Agent, under licence number: 25458636, to add me (if required) to their tax agent portal in order to proceed with this request <span class="text-red-500">*</span></p>
          <p v-for="error in props?.errors?.terms" class="mt-2 text-sm text-red-600" :id="terms-error">
          {{ error }}
          </p>
        </div>
      </div>
      <div class="relative flex items-start">
        <div class="flex items-center h-6">
          <input
              @change="$emit('update:info', $event.target.checked)"
              v-model="props.info" required id="info" aria-describedby="info-description" name="info" type="checkbox" class="w-4 h-4 text-stone-600 border-gray-300 rounded focus:ring-stone-600">
        </div>
        <div class="ml-3 text-sm leading-6">
          <label for="info" class="font-medium text-gray-900">The information provided in this application (including attachments if applicable) is true and correct <span class="text-red-500">*</span></label>
          <p id="info-description" class="text-gray-500"></p>
          <p v-for="error in props?.errors?.info" class="mt-2 text-sm text-red-600" :id="terms-error">
          {{ error }}
          </p>
        </div>
      </div>
      <Transition>
        <div v-if="doBN" class="relative flex items-start">
          <div class="flex items-center h-6">
            <input
                @change="$emit('update:ASIC_disclaimer', $event.target.checked)"
                v-model="props.bn" required id="ASIC_disclaimer" aria-describedby="ASIC_disclaimer-description" name="ASIC_disclaimer" type="checkbox" class="w-4 h-4 text-stone-600 border-gray-300 rounded focus:ring-stone-600">
          </div>
          <div class="ml-3 text-sm leading-6">
            <label for="ASIC_disclaimer" class="font-medium text-gray-900">I understand the ASIC does not allow me to change a registered bussiness name. I ensure I correctly spell my bussiness name and use the appropiate grammar. <span class="text-red-500">*</span></label>
            <p id="info-description" class="text-gray-500"></p>
            <p v-for="error in props?.errors?.bn" class="mt-2 text-sm text-red-600" :id="terms-error">
            {{ error }}
            </p>
          </div>
        </div>
      </Transition>
    </div>
  </fieldset>
</template>
